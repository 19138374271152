<template>
  <KeepAlive>
    <b-card-code>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-row>
                <!-- معلومات عن الشركة -->
                <b-col cols="12">
                  <!-- add header here -->
                </b-col>
                <b-col cols="6">
                  <b-form-group label="اسم الشركة" label-for="v--name">
                    <validation-provider #default="{ errors }" name="اسم الشركة" rules="required">
                      <b-form-input id="v--name" v-model="company.userDisplayName" :state="errors.length > 0 ? false : null" placeholder="اسم الشركة" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="رقم التسجيل الضريبي" label-for="v--nif">
                    <validation-provider #default="{ errors }" name="رقم التسجيل الضريبي" rules="required">
                      <b-form-input
                        id="v--nif"
                        type="number"
                        v-model="company.nif"
                        :state="errors.length > 0 ? false : null"
                        placeholder="رقم التسجيل الضريبي"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label="الترتيب" label-for="v--sortnumber">
                    <validation-provider #default="{ errors }" name="الترتيب" rules="required">
                      <b-form-input id="v--nif" type="number" v-model="company.sortNumber" :state="errors.length > 0 ? false : null" placeholder="الترتيب" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Username -->
                <b-col cols="6">
                  <b-form-group label="اسم المستخدم" label-for="v-username">
                    <validation-provider #default="{ errors }" name="اسم المستخدم" rules="required">
                      <b-form-input id="v-username" v-model="company.userName" :state="errors.length > 0 ? false : null" placeholder="اسم المستخدم" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Password -->
                <b-col v-if="!editCompany" cols="6">
                  <b-form-group label="كلمة المرور" label-for="v-password">
                    <validation-provider #default="{ errors }" rules="required|password" name="password" vid="password">
                      <b-form-input v-model="company.password" type="password" :state="errors.length > 0 ? false : null" placeholder="كلمة السر" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col cols="8">
                  <b-form-group label="عنوان الشارع" label-for="v-street">
                    <validation-provider #default="{ errors }" name="عنوان الشارع" rules="required">
                      <b-form-input id="v-street" v-model="company.address.street" :state="errors.length > 0 ? false : null" placeholder="عنوان الشارع" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <!-- suite -->
                  <b-form-group label="المبني" label-for="v-suite">
                    <b-form-input id="v-suite" v-model="company.address.suite" placeholder="المبني" />
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <b-form-group label="المحافظة" label-for="v-governorate">
                    <validation-provider #default="{ errors }" name="المحافظة" rules="required">
                      <vue-select id="v-governorate" v-model="selectGovernorate" label="governorate_name_ar" :options="governorates" :rtl="true" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- cities -->
                <b-col cols="4">
                  <b-form-group label="المدينة" label-for="v-city">
                    <validation-provider #default="{ errors }" name="المدينة" rules="required">
                      <vue-select
                        id="v-city"
                        v-model="company.address.city"
                        :options="cities"
                        :rtl="true"
                        label="city_name_ar"
                        :reduce="(option) => option.city_name_ar"
                        :disabled="!company.address.governorate"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="الرمز البريدي" label-for="v-postal-code">
                    <validation-provider #default="{ errors }" name="الرمز البريدي" rules="required">
                      <b-form-input
                        id="v-postal-code"
                        v-model="company.address.postalCode"
                        :state="errors.length > 0 ? false : null"
                        placeholder="الرمز البريدي"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Submit and Reset Buttons -->
            <b-col cols="12">
              <b-button
                v-if="!editCompany"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                إضافه
              </b-button>
              <b-button v-else v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1" @click.prevent="validationForm">
                تعديل
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="primary" @click="reset"> إعادة تهيئة </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="back" variant="primary" style="margin-right: 15px" @click="back"> رجوع </b-button>
            </b-col>
          </b-row>
        </b-form>

        <!-- Edit Company Form -->
        <!-- <b-form v-else @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group label="اسم الشركة" label-for="v-first-name">
                <validation-provider #default="{ errors }" name="اسم الشركة" rules="required">
                  <b-form-input id="v-name" v-model="company.userDisplayName" :state="errors.length > 0 ? false : null" placeholder="اسم الشركة" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="اسم المستخدم" label-for="v-name">
                <validation-provider #default="{ errors }" name="اسم المستخدم" rules="required">
                  <b-form-input id="v-username" v-model="company.userName" :state="errors.length > 0 ? false : null" placeholder="اسم المستخدم" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="عنوان الشارع" label-for="v-street">
                <validation-provider #default="{ errors }" name="عنوان الشارع" rules="required">
                  <b-form-input id="v-street" v-model="company.address.street" :state="errors.length > 0 ? false : null" placeholder="عنوان الشارع" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="المدينة" label-for="v-city">
                <validation-provider #default="{ errors }" name="المدينة" rules="required">
                  <b-form-input id="v-city" v-model="company.address.city" :state="errors.length > 0 ? false : null" placeholder="المدينة" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="الولاية" label-for="v-state">
                <validation-provider #default="{ errors }" name="الولاية" rules="required">
                  <b-form-input id="v-state" v-model="company.address.state" :state="errors.length > 0 ? false : null" placeholder="الولاية" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="الرمز البريدي" label-for="v-postal-code">
                <validation-provider #default="{ errors }" name="الرمز البريدي" rules="required">
                  <b-form-input id="v-postal-code" v-model="company.address.postalCode" :state="errors.length > 0 ? false : null" placeholder="الرمز البريدي" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="البلد" label-for="v-country">
                <validation-provider #default="{ errors }" name="البلد" rules="required">
                  <b-form-input id="v-country" v-model="company.address.country" :state="errors.length > 0 ? false : null" placeholder="البلد" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1" @click.prevent="validationForm">
                تعديل
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="primary" @click="reset"> إعادة تهيئة </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="back" variant="primary" style="margin-right: 15px" @click="back"> رجوع </b-button>
            </b-col>
          </b-row>
        </b-form> -->
      </validation-observer>
    </b-card-code>
  </KeepAlive>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import governoratesData from '@core/assets/governorates.json'
import citiesData from '@core/assets/cities.json'
import VueSelect from 'vue-select'

import {
  BFormCheckboxGroup,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import router from '@/router'
import Multiselect from 'vue-multiselect'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import company from '@/store/modules/company'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Multiselect,
    VueSelect
  },

  directives: {
    Ripple
  },
  props: ['id'],
  data() {
    return {
      governorates: [],
      selectGovernorate: null,

      allCities: citiesData,
      cities: [],
      company: {
        userDisplayName: '',
        userName: '',
        password: '',
        sortNumber: null,
        address: {
          street: '',
          governorate: '',
          city: '',
          suite: '',
          postalCode: '',
          country: 'مصر'
        },
        nif: null
      },
      locale: 'ar',
      required,
      editCompany: false
    }
  },
  async mounted() {
    localize(this.locale)
    this.loadGovernorates()
    if (this.id) {
      this.editCompany = true
      await this.GetCompany(this.id)
      if (this.company.address.governorate) {
        this.selectGovernorate = this.governorates.find((governorate) => governorate.governorate_name_ar === this.company.address.governorate)
      }
    } else {
      await this.GetMaxSort()
    }
  },
  watch: {
    //selectGovernorate
    selectGovernorate: function (val) {
      if (!val) {
        this.cities = []
        this.company.address.governorate = null
        this.company.address.city = null
        return
      }
      this.cities = this.allCities.filter((city) => city.governorate_id === val.id)
      this.company.address.governorate = val.governorate_name_ar
    }
  },
  methods: {
    loadGovernorates() {
      this.governorates = governoratesData
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.editCompany) {
            this.EditCompany()
          } else {
            this.AddCompany()
          }
        }
      })
    },
    async GetCompany(companyId) {
      try {
        const response = await this.$store.dispatch('GetCompany', companyId)
        if (response.status === 200) {
          this.company = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async GetMaxSort() {
      try {
        const response = await this.$store.dispatch('GetMaxSort')
        if (response.status === 200) {
          this.company.sortNumber = response.data + 1
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async AddCompany() {
      try {
        const response = await this.$store.dispatch('AddCompany', this.company)
        if (response.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الإضافة بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
          this.$router.push({ name: 'companies' })
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response ? err.response.data : 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'XIcon',
            variant: 'warning'
          }
        })
      }
    },
    async EditCompany() {
      try {
        const response = await this.$store.dispatch('UpdateCompany', this.company)
        if (response.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم التعديل بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
          this.$router.push({ name: 'companies' })
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    reset() {
      this.company = {
        userDisplayName: '',
        userName: '',
        password: '',
        address: {
          street: '',
          city: '',
          state: '',
          postalCode: '',
          country: ''
        }
      }
    },
    back() {
      this.$router.push('/companies')
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
